<template>
  <div>
    <header
      id="header-modo-ead"
      class="fd-app-mode-ead-header"
    >
      <div
        id="divBannerMeusCursos"
        class="fd-app-mode-ead-header-bg"
        :style="{
          backgroundImage:
            'url(' + $store.state.fastPlataforma.banner_plataforma + ')',
          backgroundColor: '#000',
        }"
      />
      <div class="fd-app-mode-ead-header-overlay">
        <div class="container">
          <div class="row justify-content-between align-items-center">
            <div class="col-sm-12 col-md-12 col-lg-8">
              <h1
                :style="{
                  color: $store.state.fastPersonalizacao.titulo_banner_color
                    ? $store.state.fastPersonalizacao.titulo_banner_color
                    : '#fff',
                  textShadow: '1px 1px #000',
                }"
              >
                {{
                  $store.state.fastPersonalizacao.titulo_banner
                    ? $store.state.fastPersonalizacao.titulo_banner
                    : ""
                }}
              </h1>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4">
              <mural-avisos />
            </div>
          </div>
        </div>
      </div>
    </header>
    <!-- fd-wrap -->
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb fast-menu-lives-aluno-fast">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                :href="'/plataforma/' + $route.params.id_plataforma"
                class="aluno_font_color"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Modo aluno</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno')"
              >Minhas turmas</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a href="#">Turma {{ fastTurma.sigla_turma }}</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-ead-content">
        <div class="container">
          <div class="row">
            <transition name="slide-fade">
              <component
                :is="HomeInternoAlunoMenuLateral"
                v-if="!isMobile()"
              />
            </transition>
            <div :class="isMobile() ? 'col-sm-12 col-md-12 col-lg-12' : 'col-sm-12 col-md-12 col-lg-11 fd-mode-ead-content-body'">
              <div class="fd-mode-ead-content mt-0 fast-iuea-ml-20">
                <div class="container">
                  <div class="row justify-content-center">
                    <div class="col-sm-12 col-md-12 col-lg-12">
                      <div class="row">

                        <transition name="slide-fade">
                        <div v-if="fastNavegacao.exibirModulos" class="col-sm-12 col-md-12 col-lg-12">
                          <!-- fd-app-welcome -->
                          <section
                            class="fd-app-welcome text-center fast-plataforma-iuea-hide"
                          >
                            <h1
                              class="aluno_font_color"                            
                            >
                              Turma
                              <span v-if="fastTurma.carregandoModulos">...</span>
                              <span v-else>{{ fastTurma.sigla_turma }}</span>
                            </h1>

                            <h3    
                              v-if="fastTurma.carregandoModulos"                       
                              class="aluno_font_color mt-4"
                            >
                              Carregando...
                            </h3>
                            <h3
                              v-else
                              class="aluno_font_color mt-4"
                            >
                              {{ fastTurma.nome_curso }}
                            </h3>

                            <div class="col-12 mt-4">
                              <b-button
                                variant="secondary"
                                class="ml-1 mr-1 mb-2"
                                disabled
                              >
                                <b-icon-chat-quote /> Fórum <span class="badge badge-light">0</span>
                              </b-button>
                              <b-button
                                variant="secondary"
                                class="ml-1 mr-1 mb-2"
                                disabled
                              >
                                <b-icon-people /> Pátio virtual <span class="badge badge-light">0</span>
                              </b-button>

                              <b-button v-if="fastProgramacaoLives.length"
                                variant="primary"
                                class="ml-1 mr-1 mb-2 blink_me"
                                @click="alteraFastNavegacao('exibirLives')"
                              >
                                <b-icon-camera-video /> Aulas ao vivo <span class="badge badge-light">{{fastProgramacaoLives.length}}</span>
                              </b-button>
                              <b-button v-else
                                variant="secondary"
                                class="ml-1 mr-1 mb-2"
                                disabled
                              >
                                <b-icon-camera-video /> Aulas ao vivo <span class="badge badge-light">0</span>
                              </b-button>

                              <b-button
                                v-if="fastAvaliacoesTurma.length"
                                @click.prevent="alteraFastNavegacao('exibirAvaliacoesTurma')"
                                variant="primary"
                                class="ml-1 mr-1 mb-2"
                              >
                                <b-icon-stopwatch /> Provas <span class="badge badge-light">{{fastAvaliacoesTurma.length}}</span>
                              </b-button>
                              <b-button
                                v-else
                                variant="secondary"
                                class="ml-1 mr-1 mb-2"
                                disabled
                              >
                                <b-icon-stopwatch /> Provas <span class="badge badge-light">0</span>
                              </b-button>
                              <b-button
                                variant="secondary"
                                class="ml-1 mr-1 mb-2"
                                disabled
                              >
                                <b-icon-calendar2-week /> Calendário <span class="badge badge-light">0</span>
                              </b-button>
                            </div>

                            


                            <div
                              class="fd-welcome-form-effect fast-plataforma-iuea-hide"
                            >
                              <img
                                :src="
                                  require('@/assets/images/form-effect-01.png')
                                "
                                class="mt-2"
                              >
                            </div>
                          </section>
                          <!-- /fd-app-welcome -->
                          
                          <div class="col-12 mt-4">                
                            <div
                              v-if="fastTurma.carregandoModulos"
                              class="row justify-content-around text-center"
                            >
                              <p>Carregando módulos...</p>
                            </div>
                            <div
                              v-else-if="fastTurma.modulos.length"
                              class="row"
                            >
                              <div class="col-12 text-center mt-4 mb-4">
                                <h4 v-if="fastTurma.modulos.length > 1" class="text-secondary">
                                  {{ fastTurma.modulos.length }} módulos encontrados
                                </h4>
                                <h4 v-else-if="fastTurma.modulos.length == 1" class="text-secondary">
                                  1 módulo encontrado
                                </h4>
                              </div>
                              <div
                                v-for="modulo in fastTurma.modulos"
                                :key="modulo.id_programa_turma"
                                class="col-sm-12 col-md-12 col-lg-4 mb-4"
                              >
                                <div class="card">
                                  <img
                                    class="card-img-top"
                                    :src="imageSitePlataformaImg"
                                    alt="Card image cap"
                                  >
                                  <div class="card-body">
                                    <h5 class="card-title text-center">
                                      {{ modulo.sigla_programa }}
                                    </h5>
                                    <a
                                      :href="'/plataforma/' + parseInt($route.params.id_plataforma) + '/aluno/turma/' + modulo.id_turma + '/programa/' + modulo.id_programa_turma + '?matricula=' + base64encode(base64decode(getUrlParameter('matricula')))"
                                      class="btn btn-primary btn-block"
                                    >Acessar</a>     
                                  </div>
                                </div>
                              </div>                              
                            </div>
                            <div
                              v-else
                              class="row justify-content-around text-center"
                            >
                              <p>Nenhum módulo encontrado no curso</p>
                            </div> 
                          </div>
                          <div class="col-12 mt-4">
                            <a
                              class="btn btn-red-hollow fast-iuea-ml-20"
                              :href="'/plataforma/' + $route.params.id_plataforma + '/aluno/turmas'"
                              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turmas')"
                            >
                              <b-icon-arrow-return-left /> Voltar
                            </a>
                          </div>
                        </div>
                        </transition>

                        <transition name="slide-fade">
                        <div v-if="fastNavegacao.exibirLives" class="col-sm-12 col-md-12 col-lg-12">
                          <section
                            class="fd-app-welcome text-center"
                          >
                            <h1
                              class="aluno_font_color"                            
                            >
                              Aulas 
                              <span>ao vivo</span>
                            </h1>   

                            <h4 class="mt-4">{{fastProgramacaoLives.length}} aulas encontrada(s)</h4>                         
                          </section>

                          <div class="row mt-2 mb-4">
                            <div v-for="(e, index) in fastProgramacaoLives" :key="index" class="col-sm-12 col-md-12 col-lg-12 p-2 mt-2">              
                              <b-card no-body class="shadow-sm border-none">
                                <b-row no-gutters>                                 
                                  <b-col md="6" class="d-flex align-items-center text-center">
                                    <b-card-body :title="e.titulo_aula" class="p-md-4 p-4 text-info">

                                      <span class="text-xs font-weight-bold text-secondary" v-if="e.hora_programada">Horário: {{ e.hora_programada.slice(0, -3) }} </span>

                                      <div v-if="!e.hora_passou && e.status_live != 'C' && !e.status_live == 'F'" class="card-text text-info mt-2 mb-2">
                                        <small>Aguarde o horário...</small> 
                                      </div> 
                                      <div v-else-if="!e.id_live" class="card-text  text-danger mt-2 mb-2">
                                        <small>A aula ainda não foi agendada. Aguarde...</small> 
                                      </div>  
                                      <div v-else-if="e.status_live == 'C'" class="card-text  text-danger mt-2 mb-2">
                                        <small>A aula foi cancelada</small> 
                                      </div>  
                                      <div v-else-if="e.status_live == 'F'" class="card-text  text-danger mt-2 mb-2">
                                        <small>A aula já foi finalizada</small> 
                                      </div> 

                                      <div>
                                        <a v-if="!e.id_live" href="#" @click.prevent="exibeToasty('Acesso não autorizado', 'error')" class="btn btn-secondary disabled text-white p-2 mt-2">
                                          <b-icon icon="door-open"></b-icon>  Entrar na sala
                                        </a>
                                        <a v-else-if="e.status_live == 'A' || e.status_live == 'E'" href="#" @click.prevent="verificaLiveTurma(e)" class="btn btn-primary text-white p-2 mt-2">
                                          <b-icon icon="door-open"></b-icon>  Entrar na sala
                                        </a>
                                      </div>
                                    </b-card-body>
                                  </b-col>
                                   <b-col md="6">

                                        <div class="pt-md-4 pt-0 pb-4 pl-4 pr-4">
                                          <span class="text-xs font-weight-bold text-secondary">Professor responsável</span>
                                          <div v-if="e.professoresLoading" class="col-12 text-left mt-4">
                                            <b-icon
                                              icon="gear-fill"
                                              animation="spin"
                                            />
                                            Buscando professor
                                          </div>
                                          <b-list-group v-else-if="e.professores.length">
                                            <b-list-group-item v-for="(p, index) in e.professores" :key="index">
                                              <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(p.image) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />                                                      
                                              <div class="text-center text-xs font-weight-bold text-secondary">Prof. {{p.nome_razao ? p.nome_razao : 'Sem nome'}} {{p.sobrenome_fantasia}} </div>
                                            </b-list-group-item>                                           
                                          </b-list-group>
                                          <div v-else class="col-12 text-left mt-4">
                                            Nenhum professor encontrado
                                          </div>
                                        </div>

                                        <div v-if="e.professores.length && !e.tutor_professor && e.id_live" class="pt-md-4 pt-0 pb-4 pl-4 pr-4">
                                          <span class="text-xs font-weight-bold text-secondary">Tutor</span>                                          
                                          <b-list-group>
                                            <b-list-group-item>
                                              <div :style="{ backgroundImage: 'url(' + ajustaLinkImageUser(e.image_tutor) + ')', width: '60px', height: '60px', margin: 'auto', marginBottom: '5px', borderRadius: '50%', backgroundSize: '100%', backgroundRepeat: 'no-repeat', backgroundPosition: '50%' }" />                                                      
                                              <div class="text-center text-xs font-weight-bold text-secondary">{{e.nome_tutor ? e.nome_tutor : 'Sem nome'}} {{e.sobrenome_tutor}} </div>
                                            </b-list-group-item>                                           
                                          </b-list-group>
                                        </div>
                                  
                                  </b-col>
                                </b-row>
                              </b-card>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-12">
                              <button
                                href="#"
                                type="button"
                                class="btn btn-secondary mr-1"
                                @click="alteraFastNavegacao('exibirModulos')"
                              >
                                <b-icon-arrow-return-left /> Voltar
                              </button>
                            </div>
                          </div>

                        </div>
                        </transition>

                        <transition name="slide-fade">
                          <div v-if="fastNavegacao.exibirAvaliacoesTurma" class="col-12 mt-4">
                            <div class="row mb-4">
                              <div class="col-12 text-center mb-2">
                                <span v-if="fastAvaliacoesTurma.length" class="text-xs font-weight-bold text-secondary text-uppercase" >Avaliações encontradas</span>
                                <span v-else class="text-xs font-weight-bold text-secondary text-uppercase">Nenhuma avaliação encontrada</span>
                              </div>
                              <div v-for="(e, index) in fastAvaliacoesTurma" :key="index" class="col-sm-12 col-md-12 col-lg-6 p-2 mt-2">              
                                <b-card no-body>
                                  <b-row no-gutters>
                                    <b-col md="6">
                                      <b-card-img :src="require('@/assets/images/app/plataforma-img-02.jpg')" alt="Image" class="rounded-0"></b-card-img>
                                    </b-col>
                                    <b-col md="6" class="d-flex align-items-center">
                                      <b-card-body :title="e.nome_curso ? e.nome_curso : e.nome_curso_mae" class="p-4">                        
                                        <div class="mt-2 mb-2">
                                          <button v-if="e.avaliacao_configurada && e.id_secretaria_avaliacao_principal" :class="e.id_secretaria_avaliacao_aluno_avaliacao ? 'btn btn-primary text-white p-2 mt-2' : 'btn btn-sm btn-secondary text-white p-2 mt-2'"
                                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + e.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_principal + '?tipo=A&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                                          ><b-icon-list-check /> Ir para avaliação</button>
                                        </div>
                                        <div>
                                          <button v-if="e.recuperacao_configurada && e.id_secretaria_avaliacao_recuperacao && e.id_secretaria_avaliacao_aluno_avaliacao" :class="e.id_secretaria_avaliacao_aluno_recuperacao ? 'btn btn-primary text-white p-2 mt-2' : 'btn btn-secondary text-white p-2 mt-2'"
                                          @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/aluno/turma/' + fastTurma.id_turma + '/programa/' + e.id_programa_turma + '/avaliacao/' + e.id_secretaria_avaliacao_recuperacao + '?tipo=R&matricula=' + base64encode(base64decode(getUrlParameter('matricula'))))"
                                          ><b-icon-stickies /> Ir para recuperação</button>
                                        </div>
                                      </b-card-body>
                                    </b-col>
                                  </b-row>
                                </b-card>
                              </div>
                            </div>
                            <div class="row mt-4">
                              <div class="col-12">
                                <a
                                  class="btn btn-red-hollow text-danger"
                                  @click.prevent="alteraFastNavegacao('exibirModulos')"
                                >
                                  <b-icon-arrow-return-left />
                                  Voltar
                                </a>
                              </div>
                            </div>
                          </div>
                        </transition>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /fd-wrap -->
    <!-- Modal -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import muralAvisos from "../components/MuralAvisos";
import imageSitePlataformaImg from "@/assets/images/app/plataforma-img.jpg";

export default {
  name: "HomeInternoAlunoTurma",
  components: {
    muralAvisos
  },
  mixins: [methods],
  data: function () {
    return {
      imageSitePlataformaImg,
      fastTemplate: settings.fastTemplate,
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastNavegacao: {
        exibirModulos: true,
        exibirLives: false,
        exibirAvaliacoesTurma: false,
      },
      fastMatriculaAluno: [],
      fastTurma: {
        modulos: [],
        carregandoModulos: true
      },
      // Lives
      fastProgramacaoLives: [],
      fastDataAtual: "",
      // Avaliacoes
      fastAvaliacoesTurma: []
    };
  },
  computed: {
    HomeInternoAlunoMenuLateral() {
      return () =>
        import(
          `@/templates/${this.fastTemplate}/components/HomeInternoAlunoMenuLateral.vue`
        );
    },
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_turma && this.getUrlParameter("matricula")) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'aluno')
        .then(() => {
          
          this.getFastMatriculaAluno(this.$route.params.id_plataforma, this.base64decode(this.getUrlParameter("matricula")))
          this.getFastProgramacaoLives(this.$route.params.id_plataforma, this.$route.params.id_turma) 
          

          

          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
        this.exibeToasty("Sem permissões", "error");
        this.$store.state.fastCarregando = false;
    }
  },
  methods: {
    async getFastMatriculaAluno(id_plataforma, id_matricula){
      this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista_aluno", "id_matricula=" + id_matricula + "&id_plataforma=" + id_plataforma).then(obj => {
        console.log("getFastMatriculaAluno", obj)
        this.fastMatriculaAluno = obj;
        this.getFastTurma(this.$route.params.id_plataforma, this.$route.params.id_turma)
      }).catch(e => {
        console.log(e);
      })
    },
    async getFastTurma(id_plataforma, id_turma) {
      this.fastTurma.carregandoModulos = true;
      this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
        console.log("getFastTurma", obj)
        if (obj.length) {
          obj[0].modulos = []; 
          obj[0].carregandoModulos = true         
          this.fastTurma = obj[0]
        } else {
          this.fastTurma.carregandoModulos = false;  
        }
             
      }).then(() => {
        this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma).then(obj => {
            console.log("getFastMódulos", obj)
            if (obj.length) {          
              obj.forEach((m, index) => {
                this.fastMatriculaAluno.forEach(m2 => {
                  if (m.id_programa_turma == m2.id_programa_turma) {
                    obj[index].permitido = true
                    obj[index].liberado_acesso = false
                  }
                })
              })   
              this.fastTurma.modulos = obj
              // Valida pre requisitos
              this.getFastCursosStatus(this.$route.params.id_plataforma, this.$route.params.id_turma)
            } else {
              this.fastTurma.modulos = [];
            }          
            this.fastTurma.carregandoModulos = false;  
          }).catch(e => {
            console.log(e);
          })
      })      
      .catch(e => {
        console.log(e);
      })
    },   
    // Lives
    async getFastProgramacaoLives(id_plataforma, id_turma){
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula/lista_lives", "id_plataforma=" + id_plataforma + "&id_turma=" + id_turma + "&data_programado=").then(res => {
        return res        
      })      
      .then(lives => {
        if (lives.length) {           
          this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/lista_hora", "").then(res => {
            let obj = JSON.parse(res)
            return new Date(obj.dataAtual)
          })
          .then(res => {
            this.fastDataAtual = res

            lives.forEach(e => {
              e.professores = []
              e.professoresLoading = true
              e.tutor_professor = false
              this.getProfessoresLive(e)

              e.hora_passou = false
              if (e.hora_programada) {
                let dataLive = new Date(e.data_programado.split("T")[0] + " " + e.hora_programada)
                if (this.fastDataAtual > dataLive) {                  
                  e.hora_passou = true
                }
              } else {
                e.hora_passou = true
              }      


            }); 
          
            this.fastProgramacaoLives = lives
          })
        }
      })      
      .catch(e => {
        this.exibeToasty("Erro ao recuperar aulas", "error")
      })
    },
    async getProfessoresLive(live){     
      this.promiseGetFastApi("api/fast_secretaria_turma_programacao_aula_professor/lista", `id_plataforma=${this.$route.params.id_plataforma}&id_programacao_aula=${live.id_programacao_aula}`).then(res => {
        if (res.length) {
          res.forEach(e => {
            if (e.email == live.email_tutor) live.tutor_professor = true
          })
          live.professores = res
        }
        live.professoresLoading = false
      }).catch(e => {         
        this.exibeToasty("Erro ao buscar Professores", "error")
        live.professoresLoading = false
      })
    },
    verificaLiveTurma(live){
      this.$store.state.fastCarregando = true
      const fast_secretaria_turma_programacao_aula = {
        id_turma: live.id_turma,
        id_plataforma: this.$route.params.id_plataforma,
        id_live: live.id_live
      }
      this.promisePostFastApi(fast_secretaria_turma_programacao_aula, "api/fast_secretaria_turma_programacao_aula/insere_live").then((res) => { 
        if (res.length){            
          window.location.href = `/live/${live.id_live}`
        }     
      }).catch((e) => {
          this.exibeToasty("Erro ao acessar sala de aula", "error");
          this.$store.state.fastCarregando = false
      });
    },
    async getFastAvaliacoesTurma(id_plataforma, id_turma){
      this.fastAvaliacoesTurma = []
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_aluno/avaliacoes_turma", "id_turma=" + id_turma + "&id_plataforma=" + id_plataforma)
      .then(res => {
        console.log("getFastAvaliacoesTurma", res)
        if (res.length) {          
          res.forEach(a => {
            this.fastTurma.modulos.forEach(m => {
              if (m.liberado_acesso && m.id_secretaria_curso_programa == a.id_secretaria_curso_programa) {
                this.fastAvaliacoesTurma.push(a)
              }
            })
          })
          
        }

      }).catch(e => {
        console.log(e);
      })
    },
    //#region Bloqueios
    async getFastCursosStatus(id_plataforma, id_turma) {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_secretaria_turma_programa/lista_avs?id_plataforma=" +
            id_plataforma +
            "&id_turma=" +
            id_turma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        console.log("api/fast_secretaria_turma_programa_unidade/lista_avs", json)
        let obj = Array.from(json);
        
        if (obj.length > 0) {
          obj.forEach((e) => {
            if (e.data_inicio) e.data_inicio = e.data_inicio.split("T")[0];
            if (e.data_final) e.data_final = e.data_final.split("T")[0];   
          });

          this.stepList = obj.map((e) => {
            return e.sequencia + " - " + e.nome_curso;
          });

          this.fastTurma.modulos.forEach(m => {  
            if (m.id_pai) {
              console.log("Módulo ", m.id_secretaria_curso_programa, "depende de", m.id_pai)
              let unidades_finalizadas = 0
              let unidades_totais = 0
              obj.forEach((e) => {
                if (m.id_pai == e.id_secretaria_curso_programa) {
                  console.log("A Unidade #" + e.id_curso + " " + e.nome_curso + " deve estar finalizado")
                  unidades_totais++
                  if (e.avaliacao_configurada) {
                    console.log("Unidade com avaliação")
                    if (e.aprovado_avaliacao || e.aprovado_recuperacao) {
                      console.log("Aluno aprovou na avaliação")
                      unidades_finalizadas++
                    } else {
                      console.log("Aluno não aprovou na avaliação")
                    }
                  } else {
                    console.log("Unidade sem avaliação")

                    if (e.unidade_encerrada) {
                      unidades_finalizadas++
                      console.log("Unidade encerrada")
                    } else {
                      console.log("Unidade não encerrada")
                    }
                    
                  }
                }

                if (e.id_secretaria_curso_programa == m.id_secretaria_curso_programa) {
                  //console.log("Curso " + e.id_curso)

                }
              })
              console.log("Unidades totais ", unidades_totais)
              console.log("Unidades finalizadas ", unidades_finalizadas)

              if (unidades_totais == unidades_finalizadas) m.liberado_acesso = true
            } else {
              m.liberado_acesso = true
              console.log("Módulo ", m.id_secretaria_curso_programa, " sem pré-requisito")
            }
            console.log("")
          })
          
        } else {
          
        }

        this.getFastAvaliacoesTurma(this.$route.params.id_plataforma, this.$route.params.id_turma)

        
      } catch (e) {
        console.log("Erro", e);
      }
    },
    //#endregion
    
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
</style>
